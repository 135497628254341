import React from "react";
import "./HomeMarketing.scss";
import { addOptionsToCloundinaryURL } from "app/utils/image/cloudinaryUtils";
import PropTypes from "prop-types";

const HomeMarketing = ({ image }) => {
	const source = addOptionsToCloundinaryURL(image, [("f_auto", "fl_lossy")], {
		quality: "q_auto:best",
	});

	return (
		<div className="home-marketing">
			<img src={source} />
		</div>
	);
};

HomeMarketing.propTypes = {
	image: PropTypes.string,
};

export default React.memo(HomeMarketing);
