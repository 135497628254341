import React from "react";
import ShopSwitcherContainer from "app/pages/Home/ShopSwitcher/ShopSwitcherContainer";
import ShopSwitcher from "app/pages/Home/ShopSwitcher/ShopSwitcher";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import "./HomeHeader.scss";

const HomeHeader = () => {
	return (
		<header className="home-header">
			<section className="home-header__content">
				<div className="home-header__left" />
				<div className="home-header__brand">
					<LogoBrandContainer isWhite />
				</div>
				<div className="home-header__shop">
					<ShopSwitcherContainer renderComponent={props => <ShopSwitcher {...props} />} />
				</div>
			</section>
		</header>
	);
};

export default React.memo(HomeHeader);
