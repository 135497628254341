import React from "react";
import "./HomeMarketingMobile.scss";
import { addOptionsToCloundinaryURL } from "app/utils/image/cloudinaryUtils";
import PropTypes from "prop-types";

const HomeMarketingMobile = ({ image }) => {
	const source = addOptionsToCloundinaryURL(image, ["f_auto", "fl_lossy"], {
		quality: "q_auto:best",
	});

	return (
		<div className="home-marketing-mobile">
			<img src={source} />
		</div>
	);
};

HomeMarketingMobile.propTypes = {
	image: PropTypes.string,
};

export default React.memo(HomeMarketingMobile);
