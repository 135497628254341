import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import "./shop-switcher.scss";
import Popover from "react-popover";
import IconArrowDown from "app/pages/.shared/static/icons/IconArrowDown";
import IconArrowUp from "app/pages/.shared/static/icons/IconArrowUp";
import { replaceShopInUrl } from "app/utils/urlDataExtractor";
import { sendTagOnChangeLanguage } from "app/utils/analytics";

const ShopSwitcher = ({ currentShop, shops }) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const closePopover = useCallback(() => {
		setIsPopoverOpen(false);
	}, []);

	const toogle = useCallback(() => {
		setIsPopoverOpen(!isPopoverOpen);
	}, [isPopoverOpen]);

	const switchLanguage = useCallback(code => {
		const selectedShop = code;
		const redirectURL = replaceShopInUrl(window.location.href, currentShop, selectedShop);
		window.location = redirectURL;
		sendTagOnChangeLanguage(code.slice(0, 2));
	}, []);

	return (
		shops.length > 1 && (
			<div className="shop-switcher" onClick={toogle}>
				<Popover
					className="shop-switcher__popover"
					onOuterAction={closePopover}
					body={
						<div className="shop-switcher__body">
							<ul className="shop-list">
								{shops.map(({ code, label }) => {
									const handleLanguageClick = () => {
										switchLanguage(code);
									};

									return (
										<li
											key={code}
											className="shop-list__item"
											onClick={handleLanguageClick}
										>
											<i className={`icon icon--flag-${code}`} />
											{label}
										</li>
									);
								})}
							</ul>
						</div>
					}
					isOpen={isPopoverOpen}
					preferPlace="below"
				>
					<div className="shop-switcher__button">
						<i className={`icon icon--flag-${currentShop}`} />
						{isPopoverOpen ? (
							<IconArrowUp height={16} width={16} />
						) : (
							<IconArrowDown height={16} width={16} />
						)}
					</div>
				</Popover>
			</div>
		)
	);
};

ShopSwitcher.defaultProps = {
	currentShop: "",
	baseUrl: "",
	shops: [],
	pathname: "",
};

ShopSwitcher.propTypes = {
	currentShop: PropTypes.string,
	shops: PropTypes.array,
};

export default React.memo(ShopSwitcher);
